import styled from "@emotion/styled";
import { Image } from "semantic-ui-react";
import teamImage from "../../src/assets/IMG_1195.jpg";
import Card from "../components/Card";
import { colors } from "../styles/styles";

const PageRoot = styled.section`
  .flex-container {
    position: relative;
    text-align: center;
    color: black;
  }

  .card-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-content: stretch;
    flex-wrap: wrap;
    background-color: ${colors.primaryOff};
    z-index: 3;

    @media only screen and (max-width: 1023px) {
      flex-direction: column;
      align-content: inherit;
    }
  }
`;

const ImageBackground = styled.div``;

const Container = styled.div`
  position: relative;
  text-align: center;
  color: white;

  width: 100%;
  background-image: url(${teamImage});
  background-blend-mode: screen;
  background-size: cover;
  background-position: 0% 35%;
  top: 0;
  left: 0;

  height: 70vh;

  h3 {
    overflow: hidden;
    font-size: 3vh;
    opacity: 80%;
    color: ${colors.secondaryOff};
  }

  h3:after {
    content: "";
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    width: 100%;
    margin-left: 10px;
    border-top: 1px solid ${colors.secondary};
  }

  h1 {
    color: ${colors.secondary};
    font-size: 5vh;
  }

  p {
    font-size: 2vh;
    color: ${colors.lightText};
    word-spacing: 0.25em;
  }

  .blurOverlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;
    margin-left: 5%;
    backdrop-filter: brightness(0.5);
    border: 1px ${colors.secondary} solid;
    border-style: inset;
    overflow-y: hidden;
    opacity: 90%;
    z-index: 0;
  }

  .textOverlay {
    position: relative;
    top: 0;
    right: 0;
    background-color: transparent;
    height: 100%;
    color: black;
    margin-left: 5%;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1;
  }

  @media only screen and (max-width: 1094px) {
    h1 {
      font-size: 24px;
    }

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 10px;
      color: ${colors.lightText};
      word-spacing: 0.25em;
    }

    .blurOverlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 35%;
      margin-left: 5%;
      backdrop-filter: brightness(0.5);
      border: 1px ${colors.secondary} solid;
      border-style: inset;
      overflow-y: hidden;
      opacity: 90%;
      z-index: 0;
    }

    .textOverlay {
      position: relative;
      top: 0;
      right: 0;
      background-color: transparent;
      height: 100%;
      color: black;
      margin-left: 5%;
      padding-left: 8px;
      padding-right: 8px;
      z-index: 1;
    }
  }
`;

export default function Team() {
  return (
    <PageRoot id="about-section">
      <Container>
        <ImageBackground></ImageBackground>
        <div className="blurOverlay">
          <div className="textOverlay">
            <h1>
              <b>Amos Pettry</b>
            </h1>
            <h3>
              <i>Owner and Founder</i>
            </h3>
            <p>
              With 30+ years of experience, we provide master class service with
              client satisfication in mind. Our locally owned and family driven
              business understands customer values and the importance of safety,
              professionalism, and quality.
            </p>
          </div>
        </div>
      </Container>
    </PageRoot>
  );
}
