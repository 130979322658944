import React, { useEffect, useState } from "react";
import BackgroundImage from "../../src/assets/IMG_0537.jpg";
import styled from "@emotion/styled";
import Team from "./Team";
import { colors } from "../styles/styles";
import NewServices from "./NewServices";

const MainPageLayout = styled.body`
  overflow: visible;
`;

const HomeRoot = styled.div`
  display: flex;
  justify-content: center;
  z-index: 1;
  flex-direction: row;
  height: 100vh;
  background-image: url(${BackgroundImage});
  clip-path: polygon(30% 0, 100% 0, 100% 70%, 70% 100%, 0 100%, 0% 60%, 0 30%);
  background-size: cover;
  align-text: center;

  @media only screen and (max-width: 600px) {
    clip-path: none;
    background-position-x: center;

    .carousel-container h3 {
      font-size: 48px;
      width: auto;
      margin-top: 10vh;
    }
  }

  .carousel-container {
    padding-top: 30px;
  }

  h3 {
    font-size: 7vw;
    margin-top: 50vh;
    width: 75%;
    color: ${colors.white};
    backdrop-filter: blur(1px);
    text-shadow: 2px 0px 3px rgba(0, 0, 0, 1);

    b {
      color: ${colors.secondary};
    }
  }
`;

export function Home() {
  const [windowPos, setWindowPos] = useState(window.pageYOffset);
  const _handleScroll = () => {
    let currPos = window.pageYOffset;
    setWindowPos(currPos);
    if (windowPos > currPos) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-100px";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", _handleScroll);

    return () => {
      window.removeEventListener("scroll", _handleScroll);
    };
  });

  return (
    <MainPageLayout>
      <HomeRoot>
        <div className="carousel-container">
          <h3>
            Leading the way to <b>excellence.</b>
          </h3>
        </div>
      </HomeRoot>
      <NewServices />
      <Team />
    </MainPageLayout>
  );
}
