import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Menu, Image, Icon, Button } from "semantic-ui-react";
import { colors } from "./styles/styles";
import LOGO from "./assets/pump truck_transparent.png";

type StyleProps = {
  isVisible: boolean;
};

const AppHeaderRoot = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  border-bottom: 3px solid #ccc;
  background-color: ${colors.primary}
  margin-bottom: 14px;
  transition: top 0.3s;

  .ui.menu {
    margin-bottom: 0;
    background-color: ${colors.primary};
    color: ${colors.secondary};
    flex: 1;

    @media only screen and (max-width: 1023px){
      a {
        display: none !important;
      }
      a:last-child{
        display: flex !important;
      }
    }

    .item {
      padding: 0.628571em 1.14285714em;
      color: ${colors.white} !important;

      &.logo-container {
        font-size: 1.5rem;
        font-weight: bold;
        color: ${colors.white};

        @media only screen and (max-width: 1023px)
        {
          .logo {
            height: 32px;
            width: 67px;
          }
        }

        .logo-title {
          font-size: inherit;
          margin-left: 15px;

          @media only screen and (max-width: 1023px)
        {
          font-size: 12px;
        }
      }
    }

    .item:hover{
        color: ${colors.secondary} !important;
    }
  }
`;

const Container = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
`;

const NavContainerAccordian = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 15px;
  cursor: pointer;
  right: 4.5vmin;
  display: none;
  z-index: 6;

  @media only screen and (max-width: 1094px) {
    display: block;
  }
`;

const NavOverlay = styled.ul<StyleProps>`
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: hidden;
  width: 100%;
  height: 100vh;
  transition: background 250ms linear, opacity 250ms linear;
  opacity: 1;
  background: rgba(0, 0, 0, 0.9);
  z-index: 5;
  list-style: none;
`;

const StyledLi = styled.li`
  margin-top: 50px;
  font-size: 1.66em;
  > a {
    text-decoration: none;
    background-color: transparent;
    color: white;

    :hover {
      letter-spacing: 0.125em;
    }
  }
`;

export default function AppHeader() {
  const [overlay, setOverlay] = useState(false);

  const _handleOverlay = () => {
    setOverlay(!overlay);
    if (!overlay) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  return (
    <Container isVisible={!overlay}>
      <AppHeaderRoot id="navbar">
        <Menu size="large">
          <Menu.Item className="logo-container">
            <Image
              className="logo"
              title="Logo"
              size="small"
              src={LOGO}
            ></Image>
            <span className="logo-title">Pettry Transport Services</span>
          </Menu.Item>
          <Menu.Item basic href="#services-section">
            Services
          </Menu.Item>
          <Menu.Item basic href="#about-section">
            About Us
          </Menu.Item>
          <Menu.Item
            style={{ flexDirection: "column", justifyContent: "center" }}
            basic
            href="tel:985-351-0608"
          >
            <div>
              <Icon name="phone"></Icon>
              985-351-0608
            </div>

            <div
              style={{
                color: colors.secondary,
                fontSize: "12px",
                fontStyle: "italic",
              }}
            >
              24 hour response!
            </div>
          </Menu.Item>
          <NavContainerAccordian>
            <Button color="black" icon="bars" onClick={_handleOverlay}></Button>
          </NavContainerAccordian>
        </Menu>
        <NavOverlay isVisible={overlay}>
          <StyledLi>
            <a href="#services-section" onClick={_handleOverlay}>
              Services
            </a>
          </StyledLi>
          <StyledLi>
            <a href="#about-section" onClick={_handleOverlay}>
              About Us
            </a>
          </StyledLi>
          <StyledLi>
            <a href="#footer-section" onClick={_handleOverlay}>
              Contact
            </a>
          </StyledLi>
          <StyledLi>
            <a href="tel:985-351-0608" onClick={_handleOverlay}>
              Call Now
            </a>
          </StyledLi>
        </NavOverlay>
      </AppHeaderRoot>
      {/* <ScrollLine scrolled={}></ScrollLine> */}
    </Container>
  );
}
