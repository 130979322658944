import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import AppHeader from "../AppHeader";
import { Footer } from "../Footer";
import { Home } from "../pages/Home";
import { Layout } from "./Layout";

export default function MainRoutes() {
  return (
    <div>
      <AppHeader />
      <Switch>
        <Route component={Home} exact path="" />
        <Route component={Home} exact path="/services" />
      </Switch>
      <Footer />
    </div>
  );
}
