import styled from "@emotion/styled";
import React from "react";
import { Icon } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

type ServiceCardProps = {
  title?: string;
  description?: string;
  icon?: SemanticICONS;
  vector?: string;
  backgroundColor?: string;
  textColor?: string;
};

const defaults: ServiceCardProps = {
  title: "",
  description: "",
  icon: "refresh",
  backgroundColor: "blue",
  textColor: "white",
  vector: "",
};

type CardProps = {
  backgroundColor: string;
  textColor: string;
};

const Card = styled.div<CardProps>`
  flex: 0 1 33.33333%;
  background-color: ${(x) => x.backgroundColor};
  color: ${(x) => x.textColor};
  text-align: center;
  padding: 80px 0 0;

  > h3 {
    margin-bottom: 48px;
    font-size: 3em;
  }

  > div {
  }
`;

export default function NewServiceCard(props: ServiceCardProps) {
  const { title, description, icon, backgroundColor, textColor, vector } = {
    ...defaults,
    ...props,
  };

  return (
    <Card backgroundColor={backgroundColor} textColor={textColor}>
      {vector ? (
        <img
          style={{ height: "150px", opacity: "80%" }}
          src={vector}
          alt="Phone"
        ></img>
      ) : (
        <Icon name={icon} />
      )}
      <h3>{title}</h3>
      <div>{description}</div>
    </Card>
  );
}
