export const colors = {
  neutral: "#99738E",
  primaryOff: "#2F2FA2",
  white: "#f2edec",
  // darkText: "#14161a",
  primary: "#2892D7",
  primaryAccent: "#1D70A2",
  darkText: "#1B4353",
  lightText: "#FFFBFC",
  secondary: "#FFCA3A",
  secondaryOff: "#d9c180",
  tertiary: "#E63946",
};
