import styled from "@emotion/styled";
import React from "react";
import { Icon, Image } from "semantic-ui-react";
import { colors } from "./styles/styles";
import LOGO from "./assets/pump truck.jpg";

const PageFooter = styled.footer`
  position: relative;
  z-index: 2;
  background-color: #fff;
  padding: 50px 0 25px;

  .container {
    margin: 0 auto;
    max-width: 1515px;
    padding: 0 40px;

    ul {
      list-style-type: none;
    }

    li {
      font-weight: 600;
      font-size: 15px;
      color: #070f12;
      letter-spacing: 0;
      line-height: normal;
      pointer-events: inherit;
      display: list-item;
      text-align: -webkit-match-parent;
      margin-bottom: 8px;

      a {
        color: inherit;
      }
    }
  }

  .footer-main {
    display: -ms-flexbox;
    display: flex;
    padding: 0 45px;
    margin-bottom: 60px;

    .company {
      flex: 0 1 220px;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 220px;

      a > {
        display: inline-block;
        margin-bottom: 20px;
      }
    }

    .links {
      flex: 1 1 auto;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      display: -ms-flexbox;
      display: flex;
      justify-content: flex-end;

      > nav {
        width: 100%;
        max-width: 500px;
      }

      .sub-menu {
        padding: 0;
        margin-top: 15px;
        margin-bottom: 10px;
      }
    }
  }

  .footer-social {
    h5 {
      margin: 0 0 12.5px;
      font-size: 15px;
      font-weight: 400;
      color: #383d3e;
      letter-spacing: 0;
      text-align: center;
      line-height: normal;
    }

    .icons {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .icon {
        flex: 0 1 auto;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        display: inline-block;
        padding: 12.5px;
      }
    }

    .attribution {
      text-align: center;
      line-height: normal;
      letter-spacing: 0;
      font-size: 12px;
    }
  }
`;

export function Footer() {
  return (
    <PageFooter id="footer-section">
      <div className="container">
        <div className="footer-main">
          <div className="company">
            <a href="#">
              <Image
                src={LOGO}
                size="medium"
                alt="Pettry Transport Logo"
              ></Image>
            </a>
          </div>
          <div className="links">
            <nav>
              <ul>
                <li>
                  <a
                    style={{
                      color: colors.darkText,
                      marginBottom: `15px`,
                      fontSize: "22px",
                    }}
                    href="tel:985-351-0608"
                  >
                    Contact
                  </a>
                  <ul className="sub-menu">
                    <li>
                      <a href="tel:985-351-0608">Phone: (985)-351-0608</a>
                    </li>
                    <li>Fax: (985)-370-2039</li>
                    <li>Email: PettryTransport@yahoo.com</li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="footer-social">
          <h5>Support Local Business</h5>
          <div className="icons">
            <a
              href="https://www.facebook.com/Pettry-Transport-Services-165545140304845"
              className="icon"
            >
              <Icon size="large" name="facebook f"></Icon>
            </a>
            <a
              href="https://www.linkedin.com/in/amos-pettry-516172145/"
              className="icon"
            >
              <Icon size="large" name="linkedin square"></Icon>
            </a>
          </div>
          <div className="attribution">
            Icons made by{" "}
            <a
              href="https://www.flaticon.com/authors/pixel-perfect"
              title="Pixel perfect"
            >
              Pixel perfect
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
          <div className="attribution">
            website designed and hosted by Techdog Consulting{" "}
            <a href="http://techdogconsulting.com/">Techdogconsulting.com</a>
          </div>
        </div>
      </div>
    </PageFooter>
  );
}
