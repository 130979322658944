import styled from "@emotion/styled";
import React from "react";
import { colors } from "../styles/styles";
import NewServiceCard from "./NewServiceCard";
import Oil from "../assets/oil-drum.svg";
import Septic from "../assets/tank.svg";
import Industry from "../assets/industrial-architecture-buildings.svg";
import Waste from "../assets/sump.svg";
import Grease from "../assets/deep-fryer.svg";
import Ellipse from "../assets/ellipsis.svg";

const PageRoot = styled.section`
  display: flex;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    align-content: inherit;
  }
`;

export default function NewServices() {
  return (
    <PageRoot id="services-section">
      <NewServiceCard
        title="Industrial"
        backgroundColor={colors.primary}
        vector={Industry}
      />

      <NewServiceCard
        title="Septic Tanks"
        backgroundColor={colors.tertiary}
        vector={Septic}
      />
      <NewServiceCard
        title="Oil Spills"
        backgroundColor={colors.secondary}
        textColor={colors.darkText}
        vector={Oil}
      />
      <NewServiceCard
        title="Waste Water"
        backgroundColor={colors.tertiary}
        vector={Waste}
      />
      <NewServiceCard
        title="Grease Traps"
        backgroundColor={"white"}
        textColor={colors.darkText}
        vector={Grease}
      />
      <NewServiceCard
        title="And More"
        backgroundColor={colors.primaryAccent}
        vector={Ellipse}
      />
    </PageRoot>
  );
}
