import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import MainRoutes from "./layout/MainRoutes";

function App() {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/" component={MainRoutes} />
      </Switch>
    </React.Fragment>
  );
}

export default App;
